import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import './style.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 340,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:1,
    paddingLeft:4,
    paddingRight:4,
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center'
  }
const SessionWarning = ({ timeRemaining, onStayLoggedIn, onLogout, isInactive }) => {
  return (
    <div className="session-warning-modal">
         <Modal
          open={isInactive}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="session_modal"
        >
          <Box sx={style}>
                <div className="Modal_header">
                    <h1>Session Expiration Warning</h1>
                </div>
                <div className="Modal_middle">
                    <div className="inputdrapper">
                      <p>Your session will expire in {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')} minutes.</p>
                    </div>
                </div>
                <div className="Modal_footer">
                    <button onClick={onStayLoggedIn}>Stay Logged In</button>
                    <button onClick={onLogout}>Log Out</button>
                </div>
          </Box>
        </Modal>
    </div>
  );
};

export default SessionWarning;