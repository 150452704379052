import React, { useState, useEffect, useRef } from "react";
import "./devicestyle.scss";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import alertIcon from "../../assets/images/alertIcon.svg";
import construction from "../../assets/images/construction.svg";
import constructionWhite from "../../assets/images/constructionWhite.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFloorData,
  activeFloorPlan,
} from "../../Actions/FloormapAction";
import { useScreenshot } from "use-react-screenshot";
// import FormGroup from '@mui/material/FormGroup';
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
// import { newDevicePosition, controlButtons } from "./customJson";
import { styled } from "@mui/material/styles";
import mqtt from "mqtt";
import FloorMap from "../FloorMap/FloorMap";
const url = "https://lockdownexperts.biz/api/v1";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
const TooltipContent = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
});
const ImageTooltipContent = () => (
  <TooltipContent>
    <img src={constructionWhite} style={{ display: "block" }} />
    <div>Need Repair</div>
  </TooltipContent>
);

const Devices = () => {
  const [ids, setIds] = useState(0);
  const [activeStatus, setActiveStatus] = useState([]);
  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [alarmEngaged, setAlarmEngaged] = useState(false);
  const dispatch = useDispatch();
  const [prevFloorDataLength, setPrevFloorDataLength] = useState(0);
  const [image, setImage] = useState(null);
  const [takingScreenshot, setTakingScreenshot] = useState(false);
  const [activeFloorId, setActiveFloorId] = useState(null);
  const [triggerBtn, setTriggerBtn] = useState(null);
  const [showFLoorName, setShowFLoorName] = useState(false);
  const [mesg, setMesg] = useState("Waiting on message...");
  const [switchStates, setSwitchStates] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  const newref = useRef(null);
  const mqttClient = useRef(null);

  var options = {
    protocol: "websockets",
    clientId: "string123", //This can be any unique id
  };

  const getScreenshot = (triggerBtn) => {
    var printWrapper = document.getElementById("printWrapper");

    // Set display to block
    printWrapper.style.display = "block";
    const widget = document.getElementById("printFloorMap");

    html2canvas(widget).then((canvas) => {
      canvas.toBlob((blob) => {
        setImage(blob);
        printWrapper.style.display = "none";
        setTriggerBtn(triggerBtn);
        // const reader = new FileReader();
        // reader.readAsDataURL(blob);

        // reader.onloadend = () => {
        //   const base64data = reader.result;
        //   console.log(base64data);
        //   debugger;
        // };
      });
    });
  };
  const {
    FloormapReducer: {
      floorData: floorData,
      activeFloorRef: activeFloorRef,
      activeFloorData: activeFloorData,
      activeFloorPRintData: activeFloorPRintData,
    },
  } = useSelector((state) => state);
  const setIdsfunc = (id) => {
    setIds(id);
    if (id == 11) {
      setActiveStatus(activeStatus === 3 ? "activated" : "");
    }
  };
 

  const hideDevices = async (deviceIds) => {
    // let triggers = deviceIds;
    // let allDevices = document.querySelectorAll("[id^='trigger-']");
    // let showFloorOnSS = document.getElementsByClassName("showFloor");
    // // Add class to all devices
    // allDevices.forEach((device) => {
    //   device.classList.add("active"); // replace "newClass" with the name of your class
    //   showFloorOnSS[0].classList.add("active");
    // });
    // allDevices.forEach((device) => {
    //   if (triggers?.every((id) => !device.id.endsWith(id))) {
    //     if (!device.id.endsWith(deviceIds)) {
    //       // device.style.display = "none";
    //       device.classList.remove("active");
    //     }
    //   }
    // });
    // // Take screenshot here
    // let image = await getImage();
    // // Remove class from all devices
    // allDevices.forEach((device) => {
    //   device.classList.remove("active"); // replace "newClass" with the name of your class
    //   showFloorOnSS[0].classList.remove("active");
    // });
    // setTimeout(function () {
    //   allDevices.forEach((device) => {
    //     device.style.display = "block";
    //   });
    // }, 500);
    // return image;
  };

  const isDeviceVisible = (state) => {
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        const response = await axios.get(
          "https://lockdownexperts.biz/api/v1/device/getAllLocationDevices",{
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        // const response = await axios.get(`${url}/GetDevices`);
        const devices = response.data;
        setData(devices);
        dispatch(updateFloorData(devices));
        mqttClient.current = mqtt.connect("wss://test.mosquitto.org:8081/ws");
        window.mqttConnected = true;

        var receivedMessage = false;
        mqttClient.current.on("connect", function () {
          console.log("Connected to MQTT broker");
          mqttClient.current.subscribe("niagara/#");
        });

        mqttClient.current.on("message", function (topic, message) {
          const decodedMessage = new TextDecoder("utf-8").decode(message);
          const deviceId = parseInt(topic.split("/").pop());
          if (deviceId) {
            devices
              .flatMap((x) => x.devices)
              .filter((x) => x.deviceId == deviceId)[0].state = JSON.parse(
              decodedMessage
            )
              ? 3
              : 1;
            dispatch(updateFloorData(devices));
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // const intervalId = setInterval(fetchData, 2000);

    // return () => clearInterval(intervalId);
  }, []);
  const publishMqtt = (deviceId, floorId) => {
    if (mqttClient.current != null) {
      let topic = `niagara/device/floor${floorId}/pushbtn/${deviceId}/sub`;
      mqttClient.current.publish(topic, "true");

      setTimeout(() => {
        getScreenshot(deviceId);
      }, 1000);
    }
  };

  const resetRequest = async () => {
    // stop the siren audio from playing
    const audioElement = document.getElementById("myAudio");
    if (audioElement) {
      audioElement.pause();
    }

    const deviceIds = floorData[activeTab - 1].devices
      .filter((device) => device.state === 3)
      .map((device) => device.deviceId);
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const formData = new FormData();
      formData.append("status", 1);
      formData.append("deviceIds", deviceIds);
      await axios.post(
        "https://lockdownexperts.biz/api/v1/device/resetLockdownDevice",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error making Get request:", error);
    }
  };
  console.log(triggerBtn,"triggerBtn")

  const blurHandler = (e) => {
    e.preventDefault();
    setIds(0);
  };
  useEffect(() => {
    if (triggerBtn !== null && image) {
      setLoading(true); // Set loading to true when the API request starts
      setTimeout(async function () {
        try {
          const token = JSON.parse(localStorage.getItem("token"));
          const formData = new FormData();
          let triggers = floorData[activeTab - 1].devices
            .filter((x) => x.triggeredBy?.includes(triggerBtn))
            .map((x) => x.deviceId);
          formData.append("status", 3);
          formData.append("deviceIds", triggers);
          formData.append("triggerId", triggerBtn);
          formData.append("image", image, "floorMap.png"); // Append image with filename
          await axios.post(
            "https://lockdownexperts.biz/api/v1/device/initiateLockdownDevice",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${token}`,

              },
            }
          );
          console.log("POST request successful");
          setTriggerBtn(null);

        } catch (error) {
          console.error("Error making POST request:", error);
        }finally {
          setLoading(false); // Set loading to false when the API request completes
        }
      }, 1000);
      let activePushBtn = document.getElementById(`trigger-${triggerBtn}`);
      activePushBtn && activePushBtn.classList.add("active");
    }
  }, [triggerBtn]);

  useEffect(() => {
    const triggerMap = {};
    // Check if devices exist in the active tab
    floorData[activeTab - 1]?.devices?.map((device) => {
      // Ensure triggeredBy is an array
      const triggeredBy = device.triggeredBy || [];
      if (triggeredBy.includes(0)) return; // Skip devices triggered by 0 (if that's the intended meaning)

      triggeredBy.forEach((triggerId) => {
        if (!triggerMap[triggerId]) {
          triggerMap[triggerId] = [];
        }
        triggerMap[triggerId].push(device);
      });
    });
    // Convert triggerMap to an array of entries (table format)
    const tableEntries = Object.entries(triggerMap);
    setTableData(tableEntries); // Store table data in state
  }, [floorData, activeTab]); // Make sure to include dependencies

  const handleSwitchChange = (deviceId) => (event) => {
    // Update state for the individual switch based on deviceId
    setSwitchStates((prevState) => ({
      ...prevState,
      [deviceId]: event.target.checked,
    }));
  };
  return floorData ? (
    <div className="floorMap_wrapper">
      <div className="main_heading">
        <h1>Floors</h1>
      </div>
      <div className="innerWrapper">
        <div className="floorTab">
          <ul>
            <li>
              <a
                className={activeTab === 1 ? "active " : ""}
                onClick={() => {
                  dispatch(activeFloorPlan(1));
                  setActiveTab(1);
                }}
              >
                Floor 1
                {floorData[0]?.devices
                  .filter(
                    (device) =>
                      (device.deviceId == 11 || device.deviceId == 14) &&
                      device.state === 3
                  )
                  .map((device) => device.deviceId).length > 0 ? (
                  <span>
                    <img src={alertIcon} className="alerticon" alt="img" />
                  </span>
                ) : (
                  ""
                )}
                {floorData[0]?.devices
                  .filter((device) => device.state === 2)
                  .map((device) => device.deviceId).length > 0 ? (
                  <span className="constructionImg">
                    <img
                      src={construction}
                      className="construction"
                      alt="img"
                    />
                  </span>
                ) : (
                  ""
                )}
              </a>
            </li>
            <li>
              <a
                className={activeTab === 2 ? "active " : ""}
                onClick={() => {
                  dispatch(activeFloorPlan(2));
                  setActiveTab(2);
                }}
              >
                Floor 2
                {floorData[1]?.devices
                  .filter(
                    (device) =>
                      (device.deviceId == 24 || device.deviceId == 27) &&
                      device.state === 3
                  )
                  .map((device) => device.deviceId).length > 0 ? (
                  <span>
                    <img src={alertIcon} className="alerticon" alt="img" />
                  </span>
                ) : (
                  ""
                )}
                {floorData[1]?.devices
                  .filter((device) => device.state === 2)
                  .map((device) => device.deviceId).length > 0 ? (
                  <span className="constructionImg">
                    <img
                      src={construction}
                      className="construction"
                      alt="img"
                    />
                  </span>
                ) : (
                  ""
                )}
              </a>
            </li>
          </ul>
          {/* <button className={`resetBtn`} onClick={() => resetRequest()}>
            Clear Alarms
          </button> */}
        </div>
        <div className="bottom_wrapper">
          <div
            className={`floorOneNew ${
              activeTab == 1 ? "DeviceBg" : "DeviceBgTwo"
            }`}
          >
            {/* <div className={"showFloor"}>Floor {activeTab}</div> */}
            <div className="mainBtnwrapp">
              <>
                {/* Push Btns */}
                {floorData[activeTab - 1]?.devices.map((item, index) => {
                  // Check if the deviceName contains 'pushbtn'
                  if (item.deviceName.includes("pushbtn")) {
                    return (
                      <Tooltip
                        title={
                          item.deviceType == "1" && item.state === 2 ? (
                            <ImageTooltipContent />
                          ) : item.deviceType == "4" && item.state === 2 ? (
                            <ImageTooltipContent />
                          ) : item.deviceType == "1" && item.state === 3 ? (
                            "Capturing"
                          ) : (
                            ""
                          )
                        }
                        arrow
                        placement="top"
                        open={
                          item.deviceType == "1" ||
                          (item.deviceType == "4" &&
                            (item.state === 2 || item.state === 3))
                        }
                        classes={{
                          tooltip: "customTooltip",
                          arrow: "customArrow",
                        }}
                      >
                        <div
                          key={item.deviceId}
                          className={`pushBtns pushNew_${item.deviceId}`}
                        >
                          <button
                            // key={i}
                            id={`trigger-${item.deviceId}`}
                            className={`signBtnDevices pushBtnNew  five 
                              ${
                                item.deviceType == "4" && item.state === 2
                                  ? "pushBrokenNew"
                                  : ""
                              }
                              ${ids === item.deviceId ? "active" : ""} ${
                              item.state === 3 ? "activatedNew" : ""
                            }`}
                            onClick={() =>
                              publishMqtt(item.deviceId, activeTab)
                            }
                            disabled={loading}
                          >
                            {item.deviceType === "3" && item.state === 3 && (
                              <span></span>
                            )}
                            {/* <FormGroup> */}
                            {/* <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                                            <AntSwitch 
                                            key={item.deviceId}
                                            checked={switchStates[item.deviceId] || false} // Default to false if undefined
                                            onChange={handleSwitchChange(item.deviceId)}
                                            inputProps={{ 'aria-label': 'ant design' }}
                                            />
                                          </Stack> */}
                            {/* </FormGroup> */}
                          </button>
                        </div>
                      </Tooltip>
                    );
                  }
                  return null; // Return null for devices that don't match
                })}
              </>
              <>
                {/* Camera Btns */}
                {floorData[activeTab - 1]?.devices
                  .filter((device) =>
                    device.deviceName.toLowerCase().includes("camera")
                  ) // Filter CCTV devices only
                  .map((item, index) => (
                    <Tooltip
                      title={
                        item.deviceType == "1" && item.state === 2 ? (
                          <ImageTooltipContent />
                        ) : (
                          ""
                        )
                      }
                      arrow
                      placement="top"
                      open={
                        item.deviceType == "1" ||
                        (item.deviceType == "4" &&
                          (item.state === 2 || item.state === 3))
                      }
                      classes={{
                        tooltip: "customTooltip",
                        arrow: "customArrow",
                      }}
                    >
                      <div
                        className={`camBtns camBtns_${index + 1}  cmbtn4Parent_${item.deviceId}`}
                        key={item.deviceId}
                      >
                        <button
                          id={`trigger-${item.deviceId}`}
                          className={` ${activeTab == 2 && "screentwo"} signBtnDevices camerBtnNew five ${
                            item.deviceType == "1" && item.state === 2
                              ? "cameraBrokenNew"
                              : "camerBtnNew"
                          }
                                    ${ids === item.deviceId ? "active" : ""} 
                                    ${item.state === 3 ? "activatedNew" : ""}`}
                        >
                          {item.deviceType === 3 && item.state === 3 && (
                            <span></span>
                          )}
                        </button>
                      </div>
                    </Tooltip>
                  ))}
              </>
              {/* All Btns */}
              <>
                <div className="restBtns">
                  {floorData[activeTab - 1]?.devices
                    .filter(
                      (device) =>
                        !device.deviceName.toLowerCase().includes("camera") &&
                        !device.deviceName.toLowerCase().includes("pushbtn")
                    )
                    .map((item) => (
                      <button
                        key={item.deviceId}
                        id={`trigger-${item.deviceId}`}
                        className={`signBtnDevices five 
                            ${
                              item.deviceName.toLowerCase().includes("spk")
                                ? "audioIconNew"
                                : item.deviceName.toLowerCase().includes("strb")
                                ? "commonLightNew"
                                : ""
                            } 
                            ${switchStates[item.deviceId] ? "active" : ""} 
                            ${item.state === 3 ? "activatedNew" : ""}`}
                      >
                        {item.deviceType === 3 && item.state === 3 && (
                          <span></span>
                        )}
                        {/* Add switch logic if needed */}
                      </button>
                    ))}
                </div>
              </>
            </div>
          </div>
          {/* <Footer /> */}

          {/* To Place image for screenshot */}
          {/* <div id="placeholder"></div> */}
          {/* <img
            width={`100px`}
            height={`100px`}
            src={image}
            alt={"Screenshot"}
          /> */}
        </div>
      </div>
      {
        <div id="printWrapper" style={{ display: "none" }}>
          <FloorMap printView={true} setTriggerBtnNew={setTriggerBtn} />
        </div>
      }
    </div>
  ) : (
    ""
  );
};

export default Devices;
