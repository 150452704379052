export const fieldUser = [
    {
        id:"firstName",
        name:"firstName", 
        label:"First Name",
        type:"input",
        inputType:"text",
    },
    {
        id:"lastName",
        name:"lastName", 
        label:"Last Name",
        type:"input",
        inputType:"text",
    },
    {
        id:"email",
        name:"email", 
        label:"Email Address",
        type:"input",
        inputType:"text",
    },
    // {
    //     id:"password",
    //     name:"password", 
    //     label:"Password",
    //     type:"input",
    //     inputType:"password",
    // },
    {
        id:"roleAssignment",
        name:"roleAssignment", 
        label:"Role Assignment",
        type:"select",
        data: [
            {value:"SUPER_ADMIN",label:"Administrative"},
            {value:"SUB_ADMIN",label:"Sub Admin"},
        ]
    },    
    {
        id:"status",
        name:"status", 
        label:"Status",
        type:"select",
        data: [
            {value:"ACTIVE", label:"Active"},
            {value:"INACTIVE", label:"Inactive"},
            {value:"PENDING", label:"Pending"},
        ]
    },    
]
