import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    Modal,
    NativeSelect,
    FormHelperText
} from '@mui/material';

import {
    LocalizationProvider,
    DatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import closeIcon from '../../assets/images/closeicon.svg'
import { fieldUser } from "./FieldJson"
import { Validation } from '../../Validation/Validation';
import { Container, Row, Col } from 'react-bootstrap';
import errorSign from '../../assets/images/errorSign.svg'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import checkImg from "../../assets/images/check_circle.svg";
import axios from "axios";
import {baseUrl} from '../../config/apiConfig'
import './mustyle.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};
const AddEditDevice = (props) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        // password: '',
        roleAssignment: '',
        status: '',

    });

    const [formError, setFormError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const { setOpen, open, actionButton, setActionButton, editData } = props
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const requiredFields = ["firstName", "lastName", "email", "roleAssignment", "status"];

    // Validation function
    const handleValidation = () => {
        let isValid = true;
        let errors = {};

        // Loop over required fields
        requiredFields.forEach((field) => {
            if (Validation.empty(formData[field])) {
                // General validation for other fields
                isValid = false;
                errors[field] = `Please enter ${field}`; // Generates error message like "Please enter device make"
            }
        }); // <-- Closing bracket for forEach

        // Set errors if any
        if (!isValid) {
            setFormError(errors);
        } else {
            setFormError({}); // Clear errors if valid
        }

        return isValid;
    };
    // Submit handler
    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                // password: formData.password,
                 password: "string",
                telephone: "string",
                gender: "string",
                tenantId: 712,
                tenantName: "string",
                title: "None",
                roleName: formData.roleAssignment,
                note: "Notes 1",
                userRoles: [formData.roleAssignment],
                userStatus: formData.status,
            };
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${baseUrl}/users`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setSuccessMessage(true)
                // setOpen(false);
                // setActionButton("");
                // setFormData({
                //     firstName: '',
                //     lastName: '',
                //     email: '',
                //     password: '',
                //     roleAssignment: '',
                //     status: '',
                // });
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setFormError({ email: "User with the same email already exists" });
            } else {
                console.error("Error making request:", error);
            }
            // setFormError(error);
            // setShowError(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("")
        setSuccessMessage(false)
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            // password: '',
            roleAssignment: '',
            status: '',
        });
    };
    const handleEdit = async () => {
        try {
            const formDataToSend = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                // password: formData.password,
                password: "string",
                telephone: "string",
                gender: "string",
                tenantId: 712,
                tenantName: "string",
                title: "None",
                roleName: formData.roleAssignment,
                note: "Notes 1",
                userRoles: [formData.roleAssignment],
                userStatus: formData.status,
            };
            let token = JSON.parse(localStorage.getItem("token"));
            const response = await axios.put(`${baseUrl}/user/${editData.userid}`, formDataToSend, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
            });

            if (response.status === 200) {
                setSuccessMessage(true)
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    roleAssignment: '',
                    status: '',
                });
            }
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        if (actionButton === 'add') {
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            // If editing, set form data to the existing device data
            setFormData({
                firstName: editData.name.split(" ")[0],
                lastName: editData.name.split(" ")[1],
                email: editData.emailaddress,
                roleAssignment: editData.role,
                status: editData.status,
            });
        } else {
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                // password: '',
                roleAssignment: '',
                status: '',
            });
        }
    }, [actionButton, editData]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >

        <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit New User" : "Create New User"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle  addDeviceMiddle login-form">
                    {!successMessage ? 
                        <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                            {fieldUser.map((data, index) => {
                                return (
                                    <>
                                        {
                                            data.type == "input" ?
                                                <TextField
                                                    key={index}
                                                    id={data.id}
                                                    name={data.name}
                                                    label={data.label}
                                                    variant="outlined"
                                                    type={data.inputType === "password" && showPassword ? "text" : data.inputType}
                                                    onChange={handleInputChange}
                                                    value={formData[data.name] || ''}
                                                    error={!!formError[data.name]}
                                                    className="innerFields"
                                                    helperText={formError[data.name] || data.helperText}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            transform: 'inherit',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { borderRadius: '4px' },
                                                        ...(data.inputType === "password" && {  // Conditionally add `endAdornment` for password type
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }),
                                                    }}
                                                />
                                                : data.type == "select" ?
                                                    <FormControl fullWidth className="innerFields ">
                                                        <InputLabel variant="standard" htmlFor={data.id}
                                                            shrink={false}
                                                        >
                                                            {data.label}
                                                        </InputLabel>
                                                        <NativeSelect
                                                            id={data.id}
                                                            name={data.name}
                                                            value={formData[data.name] || ''}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="" disabled>
                                                                Select an option
                                                                </option>
                                                            {data.data &&
                                                                data.data.map((role) => (
                                                                    <option key={role.value} value={role.value}>
                                                                        {role.label}
                                                                    </option>
                                                                ))}

                                                        </NativeSelect>

                                                        {/* Add FormHelperText */}
                                                        <FormHelperText>
                                                            {formError.selectedAdmins}
                                                        </FormHelperText>
                                                    </FormControl>
                                                    : " "
                                        }
                                    </>
                                )
                            })}
                        </Box> :     
                        <div className="emailsent-wrapper manageUser_details">
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>User has { actionButton == "add" ? "created" : "updated"} successfully.</p>
                            <span>Return to <a className="btnSubmit"  onClick={() => handleClose()}>manage User</a></span>
                        </div>
                    }
                </div>
                <div className="Modal_footer adddeviceFooter">
                    {/* Buttons */}
                   {!successMessage && <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" className="cancelBtn" onClick={(e) => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={(e) => handleSubmit(e)}>
                            Save
                        </Button>
                    </Box>}
                </div>
            </Box> 
        </Modal>

    );
};

export default AddEditDevice;
