// cropUtils.js

// Utility function for cropping the image
export const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimension to double the largest dimension to allow for a safe area for the
    // image to rotate around.
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on the image
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and crop it
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas size to match the crop size
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste the cropped image onto the new canvas
    ctx.putImageData(
        data,
        -safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        -safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        }, 'image/jpeg');
    });
};

// Utility function for creating an image object from a URL
const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.setAttribute('crossOrigin', 'anonymous'); // Prevent CORS issues
        image.onload = () => resolve(image);
        image.onerror = (error) => reject(error);
        image.src = url;
    });

// Rotate image utility function
export const rotateImage = async (imageSrc, rotation = 0) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas size to match image size
    canvas.width = image.width;
    canvas.height = image.height;

    // Translate canvas context to center
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    // Draw the rotated image
    ctx.drawImage(image, 0, 0);

    // Return rotated image as a new blob
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob)); // Return blob as object URL
        }, 'image/jpeg');
    });
};
