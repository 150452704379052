export const fieldUser = [
    {
        mainHead: "Email",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "SMS",
        desText: "A Brief Description goes here",
    },
    {
        mainHead: "Push Notification",
        desText: "A Brief Description goes here",
        disabled: true
    },
]

export const prefrenceData = [
    {
        id: "language",
        name: "language",
        label: "Language",
        type: "select",
        data: [
            { "value": "en_US", "label": "English (US)" },
            { "value": "en_GB", "label": "English (UK)" },
            { "value": "es_ES", "label": "Spanish (Spain)" },
            { "value": "es_MX", "label": "Spanish (Mexico)" },
            { "value": "fr_FR", "label": "French (France)" },
            { "value": "fr_CA", "label": "French (Canada)" },
            { "value": "de_DE", "label": "German" },
            { "value": "zh_CN", "label": "Chinese (Simplified)" },
            { "value": "zh_TW", "label": "Chinese (Traditional)" },
            { "value": "ja_JP", "label": "Japanese" },
            { "value": "ko_KR", "label": "Korean" },
            { "value": "it_IT", "label": "Italian" },
            { "value": "pt_PT", "label": "Portuguese (Portugal)" },
            { "value": "pt_BR", "label": "Portuguese (Brazil)" },
            { "value": "ru_RU", "label": "Russian" },
            { "value": "ar_SA", "label": "Arabic" },
            { "value": "hi_IN", "label": "Hindi" }
        ]
    },
    {
        id: "timeZone",
        name: "timeZone",
        label: "Time Zone",
        type: "select",
        data: [
            { "value": "GMT-12:00", "label": "(GMT-12:00) International Date Line West" },
            { "value": "GMT-11:00", "label": "(GMT-11:00) Coordinated Universal Time-11" },
            { "value": "GMT-10:00", "label": "(GMT-10:00) Hawaii" },
            { "value": "GMT-09:00", "label": "(GMT-09:00) Alaska" },
            { "value": "GMT-08:00", "label": "(GMT-08:00) Pacific Time (US & Canada)" },
            { "value": "GMT-07:00", "label": "(GMT-07:00) Mountain Time (US & Canada)" },
            { "value": "GMT-06:00", "label": "(GMT-06:00) Central Time (US & Canada)" },
            { "value": "GMT-05:00", "label": "(GMT-05:00) Eastern Time (US & Canada)" },
            { "value": "GMT-04:00", "label": "(GMT-04:00) Atlantic Time (Canada)" },
            { "value": "GMT-03:00", "label": "(GMT-03:00) Buenos Aires" },
            { "value": "GMT-02:00", "label": "(GMT-02:00) Coordinated Universal Time-02" },
            { "value": "GMT-01:00", "label": "(GMT-01:00) Azores" },
            { "value": "GMT+00:00", "label": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
            { "value": "GMT+01:00", "label": "(GMT+01:00) Central European Time" },
            { "value": "GMT+02:00", "label": "(GMT+02:00) Eastern European Time" },
            { "value": "GMT+03:00", "label": "(GMT+03:00) Moscow, St. Petersburg" },
            { "value": "GMT+04:00", "label": "(GMT+04:00) Abu Dhabi, Muscat" },
            { "value": "GMT+05:00", "label": "(GMT+05:00) Islamabad, Karachi" },
            { "value": "GMT+05:30", "label": "(GMT+05:30) India Standard Time" },
            { "value": "GMT+06:00", "label": "(GMT+06:00) Almaty, Novosibirsk" },
            { "value": "GMT+07:00", "label": "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
            { "value": "GMT+08:00", "label": "(GMT+08:00) Beijing, Perth, Singapore" },
            { "value": "GMT+09:00", "label": "(GMT+09:00) Tokyo, Seoul" },
            { "value": "GMT+10:00", "label": "(GMT+10:00) Brisbane, Canberra, Melbourne, Sydney" },
            { "value": "GMT+11:00", "label": "(GMT+11:00) Solomon Is., New Caledonia" },
            { "value": "GMT+12:00", "label": "(GMT+12:00) Auckland, Wellington" },
            { "value": "GMT+13:00", "label": "(GMT+13:00) Nuku'alofa" }
        ]
    },
]
