import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Modal,
    ListItemText,
    Checkbox,
    FormHelperText
} from '@mui/material';
import closeIcon from '../../assets/images/closeicon.svg';
import { fieldUser } from "./FieldJson";
import axios from "axios";
import attachmentIcon from "../../assets/images/attachmentIcon.png";
import { jwtDecode } from "jwt-decode";
import './style.scss';
import {baseUrl} from '../../config/apiConfig'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};

const AddEditFloor = (props) => {
    const [formData, setFormData] = useState({
        floor: '',
        // floorPlan: '',
        uploadedFile: null,
        selectedAdmins: []
    });
    const [formError, setFormError] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null); // Capture uploaded file
    // const [imagePreview, setImagePreview] = useState(null);
    const [userList, setUserList] = useState([]);
    const { setOpen, open, actionButton, setActionButton, editData } = props;
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const fetchData = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `${baseUrl}/getUserList`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setUserList(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        if (open) {
            fetchData()
        }
    }, [open])
    // Validation function
    const handleValidation = () => {
        let isValid = true;
        let errors = {};
        // Required fields for adding new floor
    const requiredFields = actionButton === "edit" 
    ? ["floor", "selectedAdmins"] // Do not require uploadedFile for edit
    : ["floor", "uploadedFile", "selectedAdmins"]; // Require uploadedFile for new
        // Loop over required fields
        requiredFields.forEach((field) => {
            if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
                isValid = false;
                errors[field] = `Please enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`;
            }
        });
    
        if (!isValid) {
            setFormError(errors);
        } else {
            setFormError({});
        }
    
        return isValid;
    };
    // Handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file); // Store file
            setFormData(prevState => ({
                ...prevState,
                uploadedFile: file // Add file to formData
            }));
            // setImagePreview(URL.createObjectURL(file));
        }
    };
    // Handle Apply (submit data)
    // Handle admin selection
    const handleAdminChange = (event) => {
        const value = event.target.value; // This is an array of selected userGuids
        // Map the selected userGuids back to an array of objects
        const selectedAdmins = value.map(guid => {
            const admin = userList.find(user => user.userGuid === guid);
            return {
                userGuid: guid,
                name: admin ? `${admin.firstName} ${admin.lastName}` : ''
            };
        });
        setFormData(prevState => ({
            ...prevState,
            selectedAdmins // Set the updated selectedAdmins array
        }));
    };

    // Submit handler for adding new data
    const handleAdd = async (e) => {
        e.preventDefault();
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const floorUsers = formData.selectedAdmins.map(admin => ({
                userGuid: admin.userGuid, 
                name: admin.name
            }));
            // Forming URL with query parameters (for floorName and createdBy)
            const url = new URL('https://lockdownexperts.biz/api/v1/floor/addFloor');
            url.searchParams.append('floorName', formData.floor);
            url.searchParams.append('createdBy', decodedToken.email);
            // Prepare formData for the image (if provided)
            const formDataToSend = new FormData();
            if (uploadedFile) {
                formDataToSend.append('floorImage', uploadedFile);  // Attach image file
            }
            // Add the `floorUsers` as part of the request body, not as a query parameter
            formDataToSend.append('floorUsers', JSON.stringify(floorUsers)); // Send as JSON
            // Sending the request
            const response = await axios.post(url.toString(), formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 201) {
                setOpen(false);
                setActionButton("");
                setFormError("");
                setFormData({
                    floor: '',
                    // floorPlan: '',
                    uploadedFile: null,
                    selectedAdmins: []
                });
                setUploadedFile(null)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    
    // Submit handler for editing existing data
    const handleEdit = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
    
            // Prepare floorUsers similar to handleAdd
            const floorUsers = formData.selectedAdmins.map(admin => ({
                userGuid: admin.userGuid,
                name: admin.name
            }));
    
            // Create FormData to send
            const formDataToSend = new FormData();
            formDataToSend.append('floorName', formData.floor); // Floor name from formData
            formDataToSend.append('createdBy', decodedToken.email); // Created by from decoded token
            
            // Attach uploaded file if exists
            if (uploadedFile) {
                formDataToSend.append('floorImage', uploadedFile); // Attach image file
            }
            // Add the `floorUsers` as part of the request body
            formDataToSend.append('floorUsers', JSON.stringify(floorUsers)); // Send as JSON
            // Make PUT request to update the floor
            const response = await axios.put(`https://lockdownexperts.biz/api/v1/floor/update/${editData.id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.status === 200) {
                setOpen(false);
                setActionButton("");
                setFormError("");
                setFormData({
                    floor: '',
                    uploadedFile: null,
                    selectedAdmins: []
                });
                setUploadedFile(null);
            }
        } catch (error) {
            console.error("Error updating floor:", error);
        }
    };
    // General form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) {
            return;
        }
        if (actionButton === 'add') {
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("");
        setFormData({
            floor: '',
            // floorPlan: '',
            uploadedFile: null,
            selectedAdmins: []
        });
        setUploadedFile(null)
    };

    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            console.log("Editing data:", editData); // Debugging line to check the incoming data
    
            // Extract the floorPlan URL and set it directly
            const floorPlan = editData.floorPlan || '';
            const uploadedFileName = floorPlan.split('/').pop(); // Extract the file name from the URL
    
            // Map selected admins based on the names
            const selectedAdmins = Array.isArray(editData.ConfigurationAdministrator)
            ? editData.ConfigurationAdministrator.map(name => {
                const admin = userList.find(user => `${user.firstName} ${user.lastName}` === name);
                return admin ? { userGuid: admin.userGuid, name: `${admin.firstName} ${admin.lastName}` } : null;
              }).filter(Boolean)
            : [];// Filter out any nulls
    
            // Set the formData correctly
            setFormData({
                floor: editData.floor || '',
                uploadedFileName: uploadedFileName, // Set uploadedFileName here
                selectedAdmins: selectedAdmins || [],
                floorPlan: floorPlan // Ensure floorPlan is being set
            });
        } else {
            // Reset formData for adding a new floor
            setFormData({
                floor: '',
                uploadedFileName: null, // Ensure this is set correctly for new floors
                selectedAdmins: [],
                floorPlan: '' // Reset floorPlan for new floors
            });
        }
    }, [actionButton, editData, userList]); // Ensure userList is a dependency    
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit Floor Management" : "Add Floor Management"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle addDeviceMiddle login-form">
                    <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                        {fieldUser.map((data, index) => {
                            return (
                                <>
                                    {
                                        data.type === "input" ? (
                                            <TextField
                                                key={index}
                                                id={data.id}
                                                name={data.name}
                                                label={data.label}
                                                variant="outlined"
                                                onChange={handleInputChange}
                                                value={formData[data.name] || ''}
                                                error={!!formError[data.name]}
                                                className="innerFields"
                                                helperText={formError[data.name] || data.helperText}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: { transform: 'inherit' },
                                                }}
                                            />
                                        ) : data.type === "attach" ? (
                                            <TextField
                                            key={index}
                                            label={data.label}
                                            value={(actionButton === "add" && uploadedFile?.name) || (actionButton === "edit" && formData.uploadedFileName) || ''} // Change this to uploadedFileName
                                            helperText={formError.uploadedFile}
                                            className="innerFields attachmentField"
                                            InputProps={{
                                                readOnly: true, // Prevents manual input
                                                endAdornment: (
                                                    <Button variant="contained" component="label" className="attachmentBtn">
                                                        <img src={attachmentIcon} alt="img" />
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept="image/*"
                                                            onChange={handleFileUpload}
                                                        />
                                                    </Button>
                                                ),
                                            }}
                                        />
                                        ) : (
                                                    <FormControl className="innerFields" fullWidth>
                                                        <InputLabel>Configuration Administrator</InputLabel>
                                                        <Select
                                                            multiple
                                                            value={formData.selectedAdmins.map(admin => admin.userGuid)} // Set the selected admins as an array of userGuids
                                                            onChange={handleAdminChange} // Hooking up the change handler
                                                            renderValue={(selected) => {
                                                                // Display selected usernames as a single string
                                                                return selected.map(guid => {
                                                                    const admin = userList.find(user => user.userGuid === guid);
                                                                    return admin ? `${admin.firstName} ${admin.lastName}` : '';
                                                                }).join(', ');
                                                            }}
                                                        >
                                                            {userList.map((admin) => (
                                                                <MenuItem key={admin.userGuid} value={admin.userGuid}> {/* Use userGuid for a unique value */}
                                                                    <Checkbox checked={formData.selectedAdmins.some(selected => selected.userGuid === admin.userGuid)} />
                                                                    <ListItemText primary={`${admin.firstName} ${admin.lastName}`} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                          {/* Add FormHelperText */}
                                                          <FormHelperText>
                                                            {formError.selectedAdmins}
                                                        </FormHelperText>
                                                    </FormControl>

                                                )
                                    }
                                </>
                            );
                        })}
                    </Box>
                </div>
                <div className="Modal_footer adddeviceFooter">
                    <Box sx={{ display: 'flex' }}>
                        {/* {imagePreview && <img src={imagePreview} alt="Preview" />} */}
                        <Button variant="outlined" className="cancelBtn" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Box>
                </div>
            </Box>
        </Modal>
    );
};

export default AddEditFloor;
