import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    Modal,
    NativeSelect,
    FormHelperText
} from '@mui/material';
import {
    LocalizationProvider,
    DatePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import closeIcon from '../../assets/images/closeicon.svg'
import { field } from "./FieldJson"
import { Validation } from '../../Validation/Validation';
import { Container, Row, Col } from 'react-bootstrap';
import errorSign from '../../assets/images/errorSign.svg'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import checkImg from "../../assets/images/check_circle.svg";

import axios from "axios";

import './style.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    paddingLeft: 4,
    paddingRight: 4,
};
const AddEditDevice = (props) => {
    const [formData, setFormData] = useState({
        type: {},
        name: '',
        deviceMake: '',
        status: 0,
        serialNumber: '',
        dateInstalled: null,
        macAddress: '',
        ipAddress: '',
        topicName: ''
    });

    const [formError, setFormError] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [deviceType, setDeviceType] = useState([]);
    const [statusType, setStatusType] = useState([]);
    const { setOpen, open, actionButton, setActionButton, editData } = props
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDeviceTypeChange = (e) => {
        const selectedType = deviceType.find((type) => type.id === Number(e.target.value));
        setFormData((prevState) => ({
            ...prevState,
            type: selectedType || {}
        }));
    };
    const handleStatusTypeChange = (e) => {
        const selectedStatus = statusType.find((status) => status.id === Number(e.target.value));
        setFormData((prevState) => ({
            ...prevState,
            status: selectedStatus ? selectedStatus.id : 0
        }));
    };
    // Date change handler
    const handleDateChange = (newValue) => {
        setFormData(prevState => ({
            ...prevState,
            dateInstalled: newValue ? dayjs(newValue) : null
        }));
    };
    const requiredFields = ["name", "deviceMake", "serialNumber", "dateInstalled", "macAddress", "ipAddress", "topicName"];

    // Validation function
    const handleValidation = () => {
        let isValid = true;
        let errors = {};

        // Loop over required fields
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                isValid = false;
                errors[field] = `Please enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`; // Generates error message like "Please enter device make"
            }
        });
        // Specific check for deviceType
        if (!formData.type || !formData.type.id) {
            isValid = false;
            errors.type = "Please enter a Device type";
        }
        // Validate dateInstalled
        if (!formData.dateInstalled || !formData.dateInstalled.isValid()) {
            isValid = false;
            errors.dateInstalled = "Please enter a valid installation date";
        }

        // Set errors if any
        if (!isValid) {
            setFormError(errors);
        } else {
            setFormError({}); // Clear errors if valid
        }

        return isValid;
    };
    // Submit handler
    const handleAdd = async (e) => {
        e.preventDefault();
        console.log(formData,"ppppp")
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const formDataToSend = {

                name: formData.name,
                cssXCoordinate: 0,
                cssYCoordinate: 0,
                type: formData.type,
                deviceLocationName: "string", // Placeholder
                deviceLocationId: null,          // Placeholder
                triggeredByDeviceId: [0],     // Placeholder
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: 0,
                modifiedBy: 0,
                triggeredBy: "0",        // Placeholder 
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: formData.topicName,
                floorId: null
            };
            const url = `https://lockdownexperts.biz/api/v1/device/addDevice`;
            // const response = await axios.get(url);
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json',  // Optional: Ensure content type is JSON
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (response.status === 201) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error making request:", error);
            // setFormError("Invalid credentials");
            // setShowError(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
        setActionButton("");
        setFormError("")
        setSuccessMessage(false)
        setFormData({
            name: '',
            type: {},
            deviceMake: '',
            status: 0,
            serialNumber: '',
            dateInstalled: null,
            macAddress: '',
            ipAddress: '',
            topicName: ''
        });
    };

    const handleEdit = async () => {
        try {
            const token = JSON.parse(localStorage.getItem("token"));
            const formDataToSend = {
                id: editData.id,
                name: formData.name,
                type: formData.type,
                cssXCoordinate: 0,
                cssYCoordinate: 0,
                deviceLocationName: "string", // Placeholder
                deviceLocationId: editData.deviceLocationId,
                triggeredByDeviceId: [0],     // Placeholder
                deleted: false,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                createdBy: 0,
                modifiedBy: 0,
                triggeredBy: "0",        // Placeholder
                deviceMake: formData.deviceMake,
                status: formData.status,
                serialNumber: formData.serialNumber,
                installed: formData.dateInstalled,
                macAddress: formData.macAddress,
                ipAddress: formData.ipAddress,
                topicName: formData.topicName,
                floorId: editData.floorId
            };

            const response = await axios.put(`https://lockdownexperts.biz/api/v1/device/update/${editData.id}`, formDataToSend, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, }
            });

            if (response.status === 200) {
                setSuccessMessage(true)
            }
        } catch (error) {
            console.error("Error updating device:", error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        if (actionButton === 'add') {
            console.error("Error updating device:");
            handleAdd(e);
        } else if (actionButton === 'edit') {
            handleEdit(e);
        }
    };
    // Listing APi 
    const fetchDeviceType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://lockdownexperts.biz/api/v1/deviceType/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setDeviceType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    // Listing APi 
    const fetchStatusType = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const url = `https://lockdownexperts.biz/api/v1/states/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setStatusType(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        // Call the async function
        fetchDeviceType();
        fetchStatusType();
    }, []);
    console.log(editData,"p0p0p0p0p0p;0p0pp")
    useEffect(() => {
        if (actionButton === 'edit' && editData) {
            const dateInstalledValue = editData.installed
                ? dayjs(editData.installed, 'YYYY-MM-DD')
                : null;
            // If editing, set form data to the existing device data
            setFormData({
                name: editData.name,
                type: editData.type,
                deviceMake: editData.deviceMake,
                status: editData.status,
                serialNumber: editData.serialNumber,
                dateInstalled: dateInstalledValue,  // Ensure it's a dayjs object
                macAddress: editData.macAddress,
                ipAddress: editData.ipAddress,
                topicName: editData.topicName
            });
        } else {
            setFormData({
                name: "",
                deviceMake: "",
                status: 0,
                type: {},
                serialNumber: "",
                dateInstalled: null,
                macAddress: "",
                ipAddress: "",
                topicName: ""
            });
        }
    }, [actionButton, editData]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="addFlorModal"
        >
            <Box sx={style}>
                <div className="Modal_header">
                    <h1>{actionButton === 'edit' ? "Edit Device Management" : "Add Device Management"}</h1>
                    <button onClick={() => handleClose()} className="close_button"><img src={closeIcon} alt="img" /></button>
                </div>
                <div className="Modal_middle  addDeviceMiddle login-form">
                    {!successMessage ?
                        <Box component="form" noValidate autoComplete="off" className="field_addDevice">
                            {field.map((data, index) => {
                                return (
                                    <>
                                        {
                                            data.type == "input" ?
                                                <TextField
                                                    key={index}
                                                    id={data.id}
                                                    name={data.name}
                                                    label={data.label}
                                                    variant="outlined"
                                                    onChange={handleInputChange}
                                                    value={formData[data.name] || ''}
                                                    error={!!formError[data.name]}
                                                    className="innerFields"
                                                    helperText={formError[data.name] || data.helperText}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            transform: 'inherit',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        style: { borderRadius: '4px' },
                                                    }}
                                                /> : data.type == "select" ?
                                                    <FormControl fullWidth className="innerFields">
                                                        <InputLabel variant="standard" htmlFor={data.id} shrink={false}>
                                                            {data.label}
                                                        </InputLabel>
                                                        <NativeSelect
                                                            id={data.id}
                                                            name={data.name}
                                                            value={data.name === "status" ? formData.status || '' : formData.type?.id || ''}
                                                            onChange={(e) =>
                                                                data.name === "status" ? handleStatusTypeChange(e) : handleDeviceTypeChange(e)
                                                            }
                                                        >
                                                            <option value="" disabled>
                                                                Select an option
                                                    </option>
                                                            {(data.name === "status" ? statusType : deviceType) ?.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </NativeSelect>
                                                        <FormHelperText>{formError[data.name]}</FormHelperText>
                                                    </FormControl>
                                                    : <div className="innerFields">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                label={data.label}
                                                                value={formData.dateInstalled}
                                                                onChange={handleDateChange}
                                                                renderInput={(params) => (
                                                                    <FormControl fullWidth variant="outlined">
                                                                        <InputLabel
                                                                            htmlFor={params.inputProps.id}
                                                                            shrink={false}
                                                                        >
                                                                            {data.label}
                                                                        </InputLabel>
                                                                        <TextField
                                                                            {...params}
                                                                            // error={!!formError.dateInstalled}
                                                                            fullWidth
                                                                            // helperText={formError.dateInstalled || data.helperText}
                                                                            slotProps={{
                                                                                textField: {
                                                                                    error: !!formError.dateInstalled, // Bolean
                                                                                    helperText: formError.dateInstalled, // String
                                                                                },
                                                                            }}
                                                                            variant="outlined"
                                                                            InputLabelProps={{
                                                                                shrink: false,
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                )}
                                                            />
                                                            {formError.dateInstalled && <p className="customerror">Please enter installation date</p>}
                                                        </LocalizationProvider>
                                                    </div>
                                        }
                                    </>
                                )
                            })}
                        </Box>
                        : <div className="emailsent-wrapper manageUser_details">
                            <img src={checkImg} alt="img" />
                            <h2>Success</h2>
                            <p>Device has {actionButton == "add" ? "created" : "updated"} successfully.</p>
                        </div>
                    }
                </div>
                {/* <div className="error_wraper">
                    <img src={errorSign} alt="img" />
                    {formError}
                </div> */}
                <div className="Modal_footer adddeviceFooter">
                    {/* Buttons */}
                    {!successMessage && <Box sx={{ display: 'flex' }}>
                        <Button variant="outlined" className="cancelBtn" onClick={(e) => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveBtn" onClick={(e) => handleSubmit(e)}>
                            Save
                        </Button>
                    </Box>}
                </div>
            </Box>
        </Modal>

    );
};

export default AddEditDevice;
