import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import errorSign from '../../assets/images/errorSign.svg'
import './style.scss'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 340,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:1,
    paddingLeft:4,
    paddingRight:4,
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center'
  }
const SessionWarning = ({ openDeleteModal, setOpenDeleteModal, handleDelete }) => {
    const confirmDelete = () => {
        handleDelete(); 
        setOpenDeleteModal(false);
    };
  return (
    <div className="delte_modal_wrapper">
         <Modal
          open={openDeleteModal}
        //   onClose={setOpenDeleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="delte_modal"
        >
          <Box sx={style}>
                <div className="Modal_header">
                    <h1>  <img src={errorSign} alt="img" /> Delete</h1>
                </div>
                <div className="Modal_middle">
                    <div className="inputdrapper">
                      <p>Are you sure you want to delete this device?</p>
                    </div>
                </div>
                <div className="Modal_footer">
                    <Box sx={{ display: 'flex' }}>
                            <Button variant="outlined" className="cancelBtn" onClick={() => setOpenDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="contained" className="saveBtn" onClick={confirmDelete}>
                                Save
                            </Button>
                    </Box>
                </div>
          </Box>
        </Modal>
    </div>
  );
};

export default SessionWarning;