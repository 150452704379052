import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import axios from "axios";
import errorSign from '../../assets/images/errorSign.svg';
import profilePic from '../../assets/images/profilePic.svg';
import circleOk from '../../assets/images/circleOk.svg'
import { jwtDecode } from "jwt-decode";
import checkImg from "../../assets/images/check_circle.svg";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { LoginValue } from "../../Actions/LoginPageAction";
import {baseUrl} from '../../config/apiConfig'

const ResetPassowrd = () => {
    const [formData, setFormData] = useState({ newPassword: '', confirmNewPassword: '' });
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formError, setFormError] = useState("");
    const [showError, setShowError] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({ length: false, mix: false, special: false });
    const [passwordsMatch, setPasswordsMatch] = useState(true);  // Default to true, as we don't validate until "Save"
    const [successMessage, setSuccessMessage] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Real-time validation for password strength
        if (name === "newPassword") {
            const hasLength = value.length >= 8;
            const hasMix = /[a-z]/.test(value) && /[A-Z]/.test(value);
            const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);
            setPasswordStrength({ length: hasLength, mix: hasMix, special: hasSpecial });
        }
    };

    // Validation function triggered on form submit
    const handleValidation = () => {
        let isValid = true;

        // Check password strength
        if (formData.newPassword === "") {
            setFormError("Please enter your new password.");
            isValid = false;
        } else if (!passwordStrength.length || !passwordStrength.mix || !passwordStrength.special) {
            setFormError("Password does not meet strength requirements.");
            isValid = false;
        }

        // Check if passwords match
        if (formData.confirmNewPassword === "") {
            setFormError("Please confirm your new password.");
            isValid = false;
        } else if (formData.confirmNewPassword !== formData.newPassword) {
            setFormError("Passwords do not match.");
            setPasswordsMatch(false);
            isValid = false;
        } else {
            setPasswordsMatch(true);  // If they match, reset the match status
        }

        setShowError(!isValid);
        return isValid;
    };

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;
        try {
            const formDataToSend = {
                newPassword: formData.newPassword, // Adjust the fields accordingly
                reNewPassword: formData.confirmNewPassword
            };
            const tokenUrl = window.location.href
            const params = new URLSearchParams(new URL(tokenUrl).search);
            const token = params.get('passwordToken');
            const url = `${baseUrl}/resetPassword?passwordToken=${token}`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setSuccessMessage(true);
                setFormData({ newPassword: '', confirmNewPassword: '' });  // Clear form
                setShowError(false);
                setFormError("");
            }
            //  else if (response.status === 400) {
            //     setFormError("Invalid credentials.");
            //     setShowError(true);
            // }
        } catch (error) {
            console.error("Error making request:", error);
            setFormError(error.response.data.message);
            setShowError(true);
        }
    };

    const Redirect = () => {
        setFormData({ newPassword: '', confirmNewPassword: '' });  // Clear form
        setShowError(false);
        setFormError("");
        localStorage.removeItem("token");
        navigate("/");
        dispatch(LoginValue(false));
        window.location.reload();
    }

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
                setFormError("")
            }, 5000);
        }
    }, [showError]);
    return (
        <>
            {/* <div className="error_wraper"><img src={errorSign} alt="img" />This email is incorrect. Try again with Valid Credentials.</div> */}
            <div className="right-section forgotsection resetPassword">
                {!successMessage ?
                    <div className="login-form">
                        <h2>Reset your password</h2>
                        <Box component="form" noValidate autoComplete="off" className="field">
                            <TextField
                                id="newPassword"
                                name="newPassword"
                                label="New Password"
                                type={showNewPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={handleInputChange}
                                value={formData.newPassword}
                                error={!!formError && showError}
                                // helperText={formError && showError && formError}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { transform: 'inherit' },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                id="confirmNewPassword"
                                name="confirmNewPassword"
                                label="Confirm New Password"
                                type={showConfirmPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={handleInputChange}
                                value={formData.confirmNewPassword}
                                error={!passwordsMatch && showError}  // Only show error if passwords don't match on "Save"
                                // helperText={!passwordsMatch && showError && "Passwords do not match"}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { transform: 'inherit' },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <div className="password-strength">
                            <p>Password strength:</p>
                            <div className={passwordStrength.length ? 'valid' : 'invalid'}>
                                {passwordStrength.length ? <CheckCircleOutline /> : <ErrorOutline />} At least 8 characters
                             </div>
                            <div className={passwordStrength.mix ? 'valid' : 'invalid'}>
                                {passwordStrength.mix ? <CheckCircleOutline /> : <ErrorOutline />} Upper and lowercase letters
                             </div>
                            <div className={passwordStrength.special ? 'valid' : 'invalid'}>
                                {passwordStrength.special ? <CheckCircleOutline /> : <ErrorOutline />} Special character
                             </div>
                        </div>
                        <div className="profile_wrapperbtn">
                            <button type="submit" className="btnSubmit save" onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                    :
                    <div className="emailsent-wrapper">
                        <img src={checkImg} alt="img" />
                        <h2>Reset</h2>
                        <p>Your password is successfully reset</p>
                        <a href="/SigninPage" className="btnSubmit">Login</a>
                    </div>
                }

            </div>
        </>

    );
};

export default ResetPassowrd;
