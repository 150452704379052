import React, { useState, useEffect } from 'react';
import axios from "axios";
import profilePic from '../../../assets/images/profilePic.svg';
import Pencil from "../../../assets/images/Pencil.svg";
import UpdateProfilePhoto from './UpdateProfilePhoto.js';
import { jwtDecode } from "jwt-decode";
import UserDetails from '../MyProfile/UserDtails'
import {baseUrl} from '../../../config/apiConfig'
// import './profile.scss'

const Profile = () => {
    const [open, setOpen] = useState(false);
    const [profileImage, setProfileImage] = useState("");
    const [editData, setEditData] = useState(null);
    const fetchImage = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const decodedToken = jwtDecode(token);
            const url = `${baseUrl}/user?userGuid=${decodedToken.userGuid}`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                const fetchedImage = response.data.image; // Assuming 'image' contains the image URL
                setProfileImage(fetchedImage); // Update the state with the new image URL
                setEditData(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {
        fetchImage(); // Fetch image when component loads
    }, []);
   
    return (
        <>
            <div className="name_wrapper">
                <div className="edit_name">
                    Hello Gautam
                </div>
                <div className="edit_profilePic">
                    <img src={profileImage !== "" ? profileImage : profilePic} alt="img"/>
                </div>
                <div className="name">
                    <button onClick={() => setOpen(true)}><img src={Pencil} alt="img" />Edit Photo</button>
                </div>
            </div>
            <div className="update_details">
                <UserDetails 
                       editData={editData}
                />
            </div>
            <UpdateProfilePhoto
                open={open}
                setOpen={setOpen}
                fetchImage={fetchImage}
             

            />

        </>
    );
};

export default Profile;
