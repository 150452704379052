import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Box, NativeSelect, FormControl, InputLabel, CircularProgress } from '@mui/material';
import floorOneMap from '../../assets/images/floorOneMap.png';
import undoImg from '../../assets/images/undo.svg';
import redoImg from '../../assets/images/redo.svg';
import Footer from "../Footer/Footer";
import PushButton from '../../assets/images/PushButton.svg';
import SpeakerButton from '../../assets/images/SpeakerButton.svg';
import strobeButton from '../../assets/images/strobeButton.svg';
import cameraButton from '../../assets/images/cameraButton.svg';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import './AddDevices.scss';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
const AddDevices = (props) => {
  const [items, setItems] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState();
  const [expand, setExpand] = useState(true);
  const [rows, setRows] = useState([]);
  const [floorDetails, setFloorDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const blueprintRef = useRef(null);
  const { id } = useParams();
  const columns = [
    {
      field: 'Device',
      headerName: 'Device',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={
                params.row.Type === "Camera" ? cameraButton :
                  params.row.Type === "Speaker" ? SpeakerButton :
                    params.row.Type === "Strobe" ? strobeButton :
                      params.row.Type === "Push-Button" ? PushButton :
                        ""
              }
              alt="Device"
              className="blurPrintLink"
            />
          </div>
        );
      },
    },
    {
      field: 'DeviceName',
      headerName: 'Device Name',
      flex: 2,
    },
    {
      field: 'Type',
      headerName: 'Type',
      flex: 1,
    },
    // {
    //   field: 'Status',
    //   headerName: 'Status',
    //   flex: 1,
    //   renderCell: (params) => (
    //     <div>
    //       {/* {params.value ? params.value : 'N/A'} */}
    //       {params.value == 1 ? "StandBy" : params.value == 2 ? "Failure" : params.value == 3 ? "Activated" : 'N/A'}
    //     </div>
    //   ),
    // },
  ];

  const handleImageLoad = () => {
    setIsLoading(false);
    setTimeout(() => setIsTransitioning(false), 10000);
  };
  const saveState = useCallback(
    (newItems) => {
      setUndoStack((prevStack) => [...prevStack, items]);
      setItems(newItems);
      setRedoStack([]);
    },
    [items]
  );
  

  const onDragStart = useCallback((e, params) => {
    const dragData = {
      id: params.row.id,
      name: params.row.name,
      cssXCoordinate: params.row.cssXCoordinate,
      cssYCoordinate: params.row.cssYCoordinate,
      type: params.row.type,
      deviceLocationName: selectedFloor.floorName,
      deviceLocationId: selectedFloor.id,
      triggeredByDeviceId: [0],
      deleted: false,
      createdAt: "2024-11-12T06:30:35.740Z",
      modifiedAt: "2024-11-12T06:30:35.740Z",
      createdBy: 0,
      modifiedBy: 0,
      triggeredBy: 0,
      deviceMake: "string",
      status: "string",
      serialNumber: "string",
      installed: "2024-11-12T06:30:35.740Z",
      macAddress: "string",
      ipAddress: "string",
      topicName: "string",
    };
    // Serialize only dragData (no circular references)
    e.dataTransfer.setData('itemId', JSON.stringify(dragData));
  }, [selectedFloor]);
  const onDraggedStart = useCallback((e, params) => {
    const dragData = {
      id: params.id,
      name: params.name,
      cssXCoordinate: params.cssXCoordinate,
      cssYCoordinate: params.cssYCoordinate,
      type: params.type,
      deviceLocationName: selectedFloor.floorName,
      deviceLocationId: selectedFloor.id,
      triggeredByDeviceId: [0],
      deleted: false,
      createdAt: "2024-11-12T06:30:35.740Z",
      modifiedAt: "2024-11-12T06:30:35.740Z",
      createdBy: 0,
      modifiedBy: 0,
      triggeredBy: 0,
      deviceMake: "string",
      status: "string",
      serialNumber: "string",
      installed: "2024-11-12T06:30:35.740Z",
      macAddress: "string",
      ipAddress: "string",
      topicName: "string",
    };
    // Serialize only dragData (no circular references)
    e.dataTransfer.setData('droppedItem', JSON.stringify(dragData));
  }, [selectedFloor]);

  const updateDeviceCoordinates = async (device) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/device/update/${device.id}`;
      const payload = {
        "id": device.id,
        "name": device.name,
        "cssXCoordinate": device.cssXCoordinate,
        "cssYCoordinate": device.cssYCoordinate,
        "type": device.type,
        "deviceLocationName": selectedFloor.floorName,
        "deviceLocationId": selectedFloor.id,
        "triggeredByDeviceId": [
          0
        ],
        "deleted": false,
        "createdAt": "2024-11-12T06:30:35.740Z",
        "modifiedAt": "2024-11-12T06:30:35.740Z",
        "createdBy": 0,
        "modifiedBy": 0,
        "triggeredBy": 0,
        "deviceMake": "string",
        "status": "string",
        "serialNumber": "string",
        "installed": "2024-11-12T06:30:35.740Z",
        "macAddress": "string",
        "ipAddress": "string",
        "topicName": "string"
      }
      // Make the PUT request with the complete device object
      return await axios.put(url, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error("Error updating device coordinates:", error);
    }
  };

  const deleteAddedItem = async (e,device) => {
    e.preventDefault();
    try {
        const token = JSON.parse(localStorage.getItem("token"));
        const formDataToSend = {
          "id": device.id,
          "name": device.name,
          "cssXCoordinate": 0,
          "cssYCoordinate": 0,
          "type": device.type,
          "deviceLocationName": selectedFloor.floorName,
          "deviceLocationId": null,
          "triggeredByDeviceId": [
            0
          ],
          "deleted": false,
          "createdAt": "2024-11-12T06:30:35.740Z",
          "modifiedAt": "2024-11-12T06:30:35.740Z",
          "createdBy": 0,
          "modifiedBy": 0,
          "triggeredBy": 0,
          "deviceMake": "string",
          "status": "string",
          "serialNumber": "string",
          "installed": "2024-11-12T06:30:35.740Z",
          "macAddress": "string",
          "ipAddress": "string",
          "topicName": "string"
        }
        const url = `https://lockdownexperts.biz/api/v1/device/update/${device.id}`;
        // const response = await axios.get(url);
        const response = await axios({
            method: 'PUT',
            url: url,
            data: formDataToSend,
            headers: {
                'Content-Type': 'application/json',  // Optional: Ensure content type is JSON
                'Authorization': `Bearer ${token}`,
            }
        });

        if (response.status === 200) {
             fetchData();
             fetchFloorDevices();
             setRows(response.data);
             setItems(response.data.map((device) => ({
               ...device,
               x: device.cssXCoordinate,
               y: device.cssYCoordinate,
               name: device.name,
             })));
        }
    } catch (error) {
        console.error("Error making request:", error);
    }
  };

  const onDrop = useCallback(
    async (e) => {
      e.preventDefault();

      const mapRect = blueprintRef.current.getBoundingClientRect();
      const mouseX = e.clientX - mapRect.left;
      const mouseY = e.clientY - mapRect.top;

      const xPercent = (mouseX / mapRect.width) * 100;
      const yPercent = (mouseY / mapRect.height) * 100;

      // Helper function to update device coordinates
      const updateCoordinates = async (data) => {
        if (!data) {
          console.error("No data found in drag event");
          return;
        }

        try {
          const params = JSON.parse(data); // Parse the data safely
          const updatedCordinte = {
            ...params,
            cssXCoordinate: xPercent,
            cssYCoordinate: yPercent,
          };

          // Call the backend to update coordinates
          const response = await updateDeviceCoordinates(updatedCordinte);
          if (response.status === 200) {
            await fetchData();
            await fetchFloorDevices();
          }
        } catch (error) {
          console.error("Error updating device coordinates:", error);
        }
      };

      // Retrieve and update coordinates for the newly dropped item
      const data = e.dataTransfer.getData("itemId");
      await updateCoordinates(data);

      // Retrieve and update coordinates for already dropped items
      const droppedData = e.dataTransfer.getData("droppedItem");
      if (droppedData) {
        await updateCoordinates(droppedData);
        saveState(droppedData);
      }
    },
    [items, saveState]
  );



  const onDragOver = useCallback((e) => {
    e.preventDefault();

  }, []);

  const processedRows = useMemo(() => (
    Array.isArray(rows) ? rows.map((row) => ({
      ...row,
      id: row.id,
      DeviceName: row.name || 'N/A',
      Type: typeof row.type === 'object' && row.type ? row.type.name : row.type || 'N/A',
      Status: row.status || 'N/A',
    })) : []
  ), [rows]);

  const debouncedUpdateDeviceCoordinates = useCallback(debounce(async (device) => {
    try {
      await updateDeviceCoordinates(device);
    } catch (error) {
      console.error("Error updating device coordinates:", error);
    }
  }, 300), []);

  const undo = useCallback(async () => {
    if (undoStack.length > 0) {
      const previousState = undoStack[undoStack.length - 1];
      setRedoStack((prevStack) => [...prevStack, items]);
      setUndoStack((prevStack) => prevStack.slice(0, -1));
      setItems(previousState);
  
      if (Array.isArray(previousState)) {
        for (const device of previousState) {
          if (device.cssXCoordinate && device.cssYCoordinate) {
            try {
              await updateDeviceCoordinates(device);
            } catch (error) {
              console.error("Undo failed to update device:", error);
            }
          }
        }
      }
  
      // Refresh floor devices
      await fetchFloorDevices();
    }
  }, [undoStack, items, updateDeviceCoordinates]);
  
  const redo = useCallback(async () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setUndoStack((prevStack) => [...prevStack, items]);
      setRedoStack((prevStack) => prevStack.slice(0, -1));
      setItems(nextState);
  
      if (Array.isArray(nextState)) {
        for (const device of nextState) {
          if (device.cssXCoordinate && device.cssYCoordinate) {
            try {
              await updateDeviceCoordinates(device);
            } catch (error) {
              console.error("Redo failed to update device:", error);
            }
          }
        }
      }
  
      // Refresh floor devices
      await fetchFloorDevices();
    }
  }, [redoStack, items, updateDeviceCoordinates]);
  
  
  

  // const handleFloorChange = (e) => {
  //   const selectedFloorId = e.target.value;
  //   const selectedFloor = floorDetails.find(floor => floor.id === parseInt(selectedFloorId));
  //   if (selectedFloor) {
  //     setIsTransitioning(true);
  //     setSelectedFloor(selectedFloor);
  //     setIsLoading(true);
  //   }
  // };
  const renderDraggableItems = useMemo(() => (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={processedRows}
        columns={columns.map((column) => ({
          ...column,
          renderCell: (params) => (
            <div
              draggable
              onDragStart={(e) => onDragStart(e, params)}
              className="sidbarFields"
              style={{ cursor: 'move', display: 'flex', alignItems: 'center' }}
            >
              {column.renderCell ? column.renderCell(params) : params.value}
            </div>
          ),
        }))}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 6,
            },
          },
        }}
        pageSizeOptions={[6]}
        disableRowSelectionOnClick
        pagination
        hideFooter={processedRows.length === 0} // Hide footer and pagination if no rows
      />
    </Box>
  ), [processedRows, columns, onDragStart]);

  const renderDraggableOnMap = useMemo(() => {
    if (isLoading || !Array.isArray(items)) return null;  // Early return for loading or invalid items
  
    return items.map((item) => {
      if (item.cssYCoordinate === 0 || item.cssXCoordinate === 0) return null; // Skip invalid coordinates
  
      // Handle item rendering logic
      const itemImageSrc = (() => {
        switch (item.type?.name) {
          case "Camera":
            return cameraButton;
          case "Speaker":
            return SpeakerButton;
          case "Strobe":
            return strobeButton;
          case "Push-Button":
            return PushButton;
          default:
            return "";
        }
      })();
  
      return (
        <div
          key={item.id}
          draggable
          onDragStart={(e) => onDraggedStart(e, item)}
          className="dropedItems"
          style={{
            position: 'absolute',
            top: `${item.cssYCoordinate}%`,
            left: `${item.cssXCoordinate}%`,
            cursor: 'move',
          }}
        >
          <img src={itemImageSrc} alt={`draggable item-${item.id}`} width="30" height="30" />
          <span className="deleteIcon" onClick={(e) => deleteAddedItem(e, item)}></span>
        </div>
      );
    });
  }, [items, onDraggedStart, isLoading]);
  
  
  

  const fetchData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/device/data/getDevicesMissingLocation`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setRows(response.data);
        setItems(response.data.map((device) => ({
          ...device,
          x: device.cssXCoordinate,
          y: device.cssYCoordinate,
          name: device.name,
        })));
        handleImageLoad()
      }
    } catch (error) {
      console.error("Error making request:", error);
    }
  };
  const fetchFloorDevices = async () => {
    try {
      if (!selectedFloor || !selectedFloor.id) {
        console.error("Selected floor is undefined or missing id");
        return; // Exit early if selectedFloor is invalid
      }
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/device/set-coord/data/location/${selectedFloor.id}`;
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setItems(response.data.map((device) => ({
          ...device,
          cssXCoordinate: device.cssXCoordinate,
          cssYCoordinate: device.cssYCoordinate,
        })));
      }
    } catch (error) {
      console.error("Error fetching floor devices:", error);
    }
  };
  const updatedFloor = async (id) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      const url = `https://lockdownexperts.biz/api/v1/floor/floorList`; // Fetch all floors
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        const floors = response.data;
        setFloorDetails(floors);

        // Find and set the specific floor by ID
        const selectedFloor = floors.find(floor => floor.id === parseInt(id, 10));
        if (selectedFloor) {
          setSelectedFloor(selectedFloor); // Set the selected floor in state
        } else {
          console.warn(`Floor with ID ${id} not found`);
        }
      }
    } catch (error) {
      console.error("Error fetching floor details:", error);
    }
  };
  useEffect(() => {
    if (id) {
      updatedFloor(id); // Pass ids or the specific floor ID
    }
  }, [id]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedFloor && selectedFloor.id) {
      fetchFloorDevices();
    }
  }, [selectedFloor]);

  return (
    <div className="deviceMangement_wrapper">
      <div className="main_heading main_heading_addDevices">
        <h1>Assign Devices</h1>
        <h3>{selectedFloor?.floorName}</h3>
      </div>
      <div className="AddFloor addDeviceAddFloor addDevice_flor_drop">
        <Box sx={{ minWidth: 340 }} className="floor-dropdown">
          {/* <FormControl fullWidth className="innerFields ">
            <InputLabel variant="standard" 
            // htmlFor={data.id}
              shrink={false}
            >
              Floor
            </InputLabel>
            <NativeSelect
              // id={data.id}
              // name={data.name}
              // value={formData.type ?.id || ''}
              onChange={(e) => handleFloorChange(e)}
            >
              <option value="" disabled>
                Select an option
              </option>
              {floorDetails &&
                floorDetails.map((floor) => (
                  <option key={floor.id} value={floor.id}>
                    {floor.floorName}
                  </option>
                ))}

            </NativeSelect>
          </FormControl> */}
          <div className="undoRdoBtn">
            <button onClick={undo} disabled={undoStack.length === 0}>
              <img src={undoImg} alt="img" />
            </button>
            <button onClick={redo} disabled={redoStack.length === 0}>
              <img src={redoImg} alt="img" />
            </button>
          </div>
        </Box>
        {/* <button className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Add Device</button> */}
      </div>
      <div className="inner_Wrapper addDeviceInnerWrppar">
        <div className="mapwrapper">
          <div
            className={`inner_addDevicewrapper ${expand ? "active" : "inactive"}`}
            onDrop={onDrop}
            onDragOver={onDragOver}
          >
            {!isLoading ?
              <img src={selectedFloor?.floorImageUrl || "No BluePrint"} className={`main_bg_addv ${isTransitioning ? 'fade-in' : ''}`} alt="Blueprint" ref={blueprintRef} />
              :
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            }
            {!isLoading && renderDraggableOnMap}
            <Footer />
          </div>
          <div className={`inner_sideBar ${expand ? "active" : "inactive"}`}>
            <button className="excpBtn" onClick={() => setExpand(!expand)}></button>
            {renderDraggableItems}
          </div>
        </div>
      </div>

    </div>
  );
};

export default AddDevices;
