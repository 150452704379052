export const fieldUser = [
    {
        id:"floor",
        name:"floor", 
        label:"Floor",
        type:"input",
        inputType:"text",
    },
    {
        id:"floorPlan",
        name:"floorPlan", 
        label:"Floor Plan",
        type:"attach",
        inputType:"attachment",
    },
    {
        id:"configurationAdministrator",
        name:"configurationAdministrator", 
        label:"Configuration Administrator",
        type:"select",
    },
   
]