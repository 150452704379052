import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import Header from "./Component/Header/Header";
import SideBar from "./Component/SideBar/SideBar";
import FloorMap from "./Component/FloorMap/FloorMap";
import LoginPage from "./Component/LoginPage/LoginPage";
import Dashboard from "./Component/Dashboard/Dashboard";
import FloorManagement from "./Component/FloorManagement/FloorManagement";
import Devices from "./Component/Devices/Devices";
import DevicesComp from "./Component/DevicesComp/DevicesComp";
import HistoryComp from "./Component/HistoryComp/HistoryComp";
import Analytics from "./Component/Analytics/Analytics";
import Help from "./Component/Help/Help";
import Profile from "./Component/Profile/Profile";
import MangeUser from "./Component/MangeUser/MangeUser";
import AddDevices from "./Component/FloorManagement/AddDevices";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import SessionWarning from "./Component/SessionWarning/SessionWarning";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  const [userDataStorage, setUserDataStorage] = useState("");
  const [isInactive, setIsInactive] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(10);
  const [modalActive, setModalActive] = useState(false);
  const newref = useRef(null);
  const {
    LogInReducer: { loginDetail },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let inactivityTimeout; // To store inactivity timeout ID
  let countdownInterval; // To store countdown interval ID

  // Function to start inactivity timer
  const startInactivityTimer = () => {
    clearTimeout(inactivityTimeout); // Clear any existing timers
    inactivityTimeout = setTimeout(() => {
      // After 12 min of inactivity, show session warning
      setIsInactive(true);
      setModalActive(true); // Set modal as active
      startCountdown();
    }, 12 * 60 * 1000); // 12 min in milliseconds
  };

  // Countdown for the session warning
  const startCountdown = () => {
    clearInterval(countdownInterval); // Clear existing countdown
    setTimeRemaining(180); // Set countdown duration to 3 min (for the modal)
    countdownInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdownInterval);
          handleLogout(); // Auto-logout when countdown reaches zero
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // Decrease by 1 second
  };

  // Reset session (called when user clicks "Stay Logged In")
  const resetSession = () => {
    setIsInactive(false);
    setTimeRemaining(30); // Reset time remaining for testing
    clearInterval(countdownInterval); // Clear countdown interval
    startInactivityTimer(); // Restart inactivity timer
    setModalActive(false); // Close the modal
    navigate(location.pathname); // Redirect to Dashboard
  };

  // Handle Logout
  const handleLogout = () => {
    clearTimeout(inactivityTimeout);
    clearInterval(countdownInterval);
    setIsInactive(false); // Reset inactive state
    setModalActive(false); // Ensure the modal is not active
    setUserDataStorage("");
    localStorage.removeItem("token");
    dispatch({ type: "LOGOUT" }); // Dispatch logout action
    // navigate("/", { state: { message: "Your session has expired due to inactivity." } });
  };

  // Check token on every load, reload, and route change
  useEffect(() => {
    const checkToken = () => {
      const storedData = JSON.parse(localStorage.getItem("token"));
      if (storedData) {
        setUserDataStorage(storedData);
        startInactivityTimer(); // Start the inactivity timer
      } else {
        handleLogout(); // If token isn't available, log out and redirect
      }
    };

    checkToken(); // Check token on component mount and on route change

    // Event listeners for user activity
    const resetInactivityTimer = () => {
      if (!modalActive) {
        // Reset only if the modal is not active
        setIsInactive(false); // Reset inactive state
        setTimeRemaining(180); // Reset time remaining for testing
        clearTimeout(inactivityTimeout); // Clear existing inactivity timeout
        startInactivityTimer(); // Restart inactivity timer
      }
    };

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);
    window.addEventListener("click", resetInactivityTimer);

    // Cleanup when component unmounts
    return () => {
      clearTimeout(inactivityTimeout);
      clearInterval(countdownInterval);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
      window.removeEventListener("click", resetInactivityTimer);
    };
  }, [location, loginDetail, modalActive]); // Add location as a dependency to detect route changes

  const isDevicesPage = location.pathname.includes("/devices");

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        {/* Conditional rendering based on userDataStorage */}
        {userDataStorage === "" ? (
          <Routes>
            {/* Define the login route */}
            <Route path="/" element={<LoginPage />} />
            <Route path="/ForgotPassword" element={<LoginPage />} />
            <Route path="/resetPassword" element={<LoginPage />} />
            {/* Redirect any unknown routes to the login page */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <>
            <Header />
            <div className="middle_container">
              {!isDevicesPage && <SideBar />}
              <Routes>
                {/* Define your routes here */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/floors" element={<FloorMap newref={newref} />} />
                <Route path="/floorManagement" element={<FloorManagement />} />
                <Route path="/manageUser" element={<MangeUser />} />
                <Route path="/devices" element={<Devices />} />
                <Route path="/device" element={<DevicesComp />} />
                <Route path="/history" element={<HistoryComp />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/help" element={<Help />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/AddDevices/:id" element={<AddDevices />} />
                {/* If no route matches, redirect to floors */}
                <Route path="*" element={<Navigate to="/floors" replace />} />
              </Routes>
            </div>
          </>
        )}
        {/* Show SessionWarning if inactive */}
        {isInactive &&
          modalActive &&
          userDataStorage !== "" && ( // Show modal only if inactive
            <SessionWarning
              timeRemaining={timeRemaining}
              onStayLoggedIn={resetSession}
              onLogout={handleLogout}
              isInactive={isInactive}
            />
          )}
      </div>
    </DndProvider>
  );
}

export default App;
