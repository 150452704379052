const initialState = {
    loginDetail:false
}
const LogInReducer = (state = initialState, action) =>{
    switch(action.type){
        case "LogIn" :
        return {
            ...state,
            loginDetail:action.payload
        }
        default:
        return state
    }
}

export default LogInReducer