export const updateFloorData = (value) => ({
    type: "FloorData",
    payload: value,
});
  
export const activeFloorPlan = (value) => ({
    type: "activeFloorPlan",
    payload: value,
});
  
export const activeFloorPrintView = (value) => (
    console.log(value,"activeFloorPrintView"),{
    type: "SET_ACTIVE_FLOOR_PRINT_VIEW",
    payload: value,
}
);

export const floorMapRef = (value) => ({
    type: "SET_ACTIVE_FLOOR_REF",
    payload: value,
}
);