import React, { useState, useEffect } from 'react';
import { Box, NativeSelect, FormControl, InputLabel, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '../../assets/images/editIcon.svg'; // Example using MUI icons
import DeleteIcon from '../../assets/images/DeleteIcon.svg'; // Example using MUI icons
import AddEditDevice from './AddEditDevice.js'
import DeleteModal from './DeleteModal'
import axios from "axios";
import './style.scss'

const DevicesComp = () => {
    const [open, setOpen] = useState(false);
    const [openToast, setOpenToast] = useState(false);
    const [message, setMessage] = useState('Delete Successfully.');
    const [selectedFloor, setSelectedFloor] = useState(1);
    const [rows, setRows] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [actionButton, setActionButton] = useState("")
    const [editData, setEditData] = useState(null);
    const [deviceIdToDelete, setDeviceIdToDelete] = useState(null);
    const columns = [
        {
            field: 'name',
            headerName: 'Device Name',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'deviceMake',
            headerName: 'Device Make',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'serialNumber',
            headerName: 'Serial Number',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'installed',
            headerName: 'Date Installed ',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'macAddress',
            headerName: 'MACAddress',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'ipAddress',
            headerName: 'IP Address',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'topicName',
            headerName: 'Topic Name',
            flex: 1,
            sortable: false,
            disableColumnMenu: true
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: (params) => (
              
                <div>
                    <img
                        src={EditIcon}
                        alt="edit"
                        style={{ width: '28px', height: '28px', cursor: 'pointer', marginRight: '10px' }}
                        onClick={() => handleEdit(params.row)}
                    />
                    <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                        onClick={() => { setOpenDeleteModal(true); setDeviceIdToDelete(params.id) }}
                    />
                </div>
            ),
        }
    ]
    const handleEdit = (deviceData) => {
        setEditData(deviceData);
        setActionButton("edit");
        setOpen(true);
    };

    const selectedFloorHandler = (e) => {
        setSelectedFloor(e.target.value)
    }

    const processedRows = Array.isArray(rows) ? rows.map(row => ({
        id: row.id, // Assuming each row needs an `id` field
        name: row.name || 'N/A',
        deviceMake: row.deviceMake || 'N/A',
        serialNumber: row.serialNumber || 'N/A',
        installed: row.installed || 'N/A',
        macAddress: row.macAddress || 'N/A',
        ipAddress: row.ipAddress || 'N/A',
        topicName: row.topicName || 'N/A',
        floorId: row.floorId || 'N/A',
        deviceLocationId: row.deviceLocationId || 'N/A',
        type: row.type || 'N/A',
        status: row.status || 'N/A'
    })) : [];

    const fetchData = async () => {
        try {
            // Add selectedFloor as a query parameter
            // const url = `https://lockdownexperts.biz/api/v1/device/data/floor/${selectedFloor}`;
            const token = JSON.parse(localStorage.getItem("token"));
            const url = `https://lockdownexperts.biz/api/v1/device/data/all`
            const response = await axios({
                method: 'GET',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
            });

            if (response.status === 200) {
                setRows(response.data)
            }
        } catch (error) {
            console.error("Error making request:", error);
        }
    };
    useEffect(() => {

        // Call the async function
        fetchData();
    }, [actionButton]);

    const handleDelete = async (id) => {
        let token = JSON.parse(localStorage.getItem("token"));
        const url = `https://lockdownexperts.biz/api/v1/device/${id}`; // Construct the URL with the device ID
        try {
            const response = await axios.delete(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status == 200) {
                fetchData();
                setOpenToast(true)
            } else {
                console.error("Failed to delete device:", response.data);
                // Handle any errors here, such as displaying a message to the user
            }
        } catch (error) {
            console.error("Error deleting device:", error);
            // Handle any errors here, such as displaying a message to the user
        }
    };
    const handleToastClose = () => {
        setOpenToast(false)
        setMessage("")
    };
    return (
        <>
            <div className="deviceMangement_wrapper">
                <div
                    className="main_heading"
                >
                    <h1>Device Management</h1>
                </div>
                <div className="AddFloor">
                    {/* <div className="floor-dropdown">
                        <Box sx={{ minWidth: 340 }}>
                            <FormControl fullWidth>
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Floor
                            </InputLabel>
                                <NativeSelect
                                    defaultValue={1}
                                    inputProps={{
                                        name: 'age',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={(e) => selectedFloorHandler(e)}
                                >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </div> */}

                    <button className="addDevice" onClick={() => { setOpen(true); setActionButton("add") }}>Add Device</button>
                </div>
                <div className="inner_Wrapper">
                    <div className="floorMangementTable_wrapper">
                        <Box sx={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={processedRows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
            <AddEditDevice
                open={open}
                setOpen={setOpen}
                setActionButton={setActionButton}
                actionButton={actionButton}
                editData={editData}
            />
            <DeleteModal
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                handleDelete={() => handleDelete(deviceIdToDelete)}
            />
            <Snackbar
                open={openToast}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                message={message}
                onClose={handleToastClose}
                className={"success"}
            />
        </>

    )
}

export default DevicesComp
