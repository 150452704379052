import React from 'react'
import '../profile.scss'
import notepad from "../../../assets/images/Notepad.svg"
const ActivityLog = () => {
    return (
        <div className="activityLog_wrapper">
                <h3>Activity Log</h3>
                <div className="viewLog_wrapper">
                    <div className="viewLogs_details">
                        <p>Last Login Date & TIme</p>
                        <span>1/29/2024 11:41:38 AM</span>
                    </div>
                    <div className="viewLogs_details">
                        <p>Last Login IP</p>
                        <span>73.70.216.96</span>
                    </div>
                    <div className="viewLogs_details">
                        <p>Last Login Location</p>
                        <span>Texas, United States</span>
                    </div>
                    <div className="viewLogs_details">
                        <p>Login Count</p>
                        <span>134</span>
                    </div>
                    <div className="viewLogs_details">
                            <a href="#"><img src={notepad} alt="img" />View Log</a>
                    </div>
                </div>
        </div>
    )
}

export default ActivityLog
