export const fieldUser = [
    {
        id:"userName",
        name:"userName", 
        label:"User name",
        type:"input",
        inputType:"text",
        disabled:true
    },
    {
        id:"accountType",
        name:"accountType", 
        label:"Account Type",
        type:"input",
        inputType:"text",
        disabled:true
    },
    {
        id:"firstName",
        name:"firstName", 
        label:"First Name",
        type:"input",
        inputType:"text",
    },
    {
        id:"lastName",
        name:"lastName", 
        label:"Last Name",
        type:"input",
        inputType:"text",
    },
    {
        id:"phoneNumber",
        name:"phoneNumber", 
        label:"Phone Number",
        type:"input",
        inputType:"text",
    },
    {
        id:"email",
        name:"email", 
        label:"Email",
        type:"input",
        inputType:"text",
    },
]
