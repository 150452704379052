import React from 'react'

const HistoryComp = () => {
    return (
        <div>
            History
        </div>
    )
}

export default HistoryComp
