import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import axios from "axios";
import { LoginValue } from "../../Actions/LoginPageAction";
import { Validation } from '../../Validation/Validation';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import errorSign from '../../assets/images/errorSign.svg';
import { baseUrl } from '../../config/apiConfig';
import './style.scss';

const SigninPage = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [formError, setFormError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showError, setShowError] = useState(false); // Make sure to define this state
    const dispatch = useDispatch();

    // Generalized input change handler
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validation function
    const handleValidation = () => {
        let isValid = true;

        if (Validation.empty(formData.username)) {
            setFormError("Please enter your Username");
            isValid = false;
        }
        if (Validation.empty(formData.password)) {
            setFormError("Please enter your Password");
            isValid = false;
        }
        setShowError(true);
        return isValid;
    };

    // Submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) return;

        try {
            const encodedPassword = btoa(formData.password);
            const formDataToSend = {
                email: formData.username,
                password: formData.password
            };

            const url = `${baseUrl}/users/authenticate`;
            const response = await axios({
                method: 'POST',
                url: url,
                data: formDataToSend,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                const token = response.data?.token;
                if (token) {
                    localStorage.setItem("token", JSON.stringify(response.data?.token));
                    dispatch(LoginValue(true));
                    setFormData({ username: '', password: '' }); // Clear form
                    setShowError(false);
                    setFormError("");
                }
            } else if (response.status === 400) {
                setFormError(response.message);
                setShowError(true);
            }
        } catch (error) {
            console.error("Error making request:", error);
            setFormError("Invalid credentials");
            setShowError(true);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
                setFormError("");
            }, 5000);
        }
    }, [showError]);

    // Key press handler to submit on "Enter" key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    return (
        <>
            {(showError && formError !== "") && (
                <div className="error_wraper">
                    <img src={errorSign} alt="Error" />
                    {formError}
                </div>
            )}
            <div className="right-section">
                <div className="login-form">
                    <h2>Welcome back!</h2>
                    <form className="field" onSubmit={handleSubmit}>
                        <Box component="form" noValidate autoComplete="off" className="field">
                            <TextField
                                id="username"
                                name="username"
                                label="Email/Username"
                                variant="outlined"
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                value={formData.username}
                                error={!!formError && showError}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        transform: 'inherit',
                                    },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                }}
                            />
                            <TextField
                                id="password"
                                name="password"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                variant="outlined"
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                value={formData.password}
                                error={!!formError && showError}
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        transform: 'inherit',
                                    },
                                }}
                                InputProps={{
                                    style: { borderRadius: '4px' },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={togglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <a href="/ForgotPassword" target="_blank" rel="noopener noreferrer" className="forgot-password">Forgot Password?</a>
                        <button type="submit" className="btnSubmit">Sign in</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SigninPage;
