export const field = [
    {
        id:"deviceType",
        name:"deviceType", 
        label:"Device Type",
        type:"select",
    },
    {
        id:"name",
        name:"name", 
        label:"Device Name",
        type:"input",
    },
    {
        id:"deviceMake",
        name:"deviceMake", 
        label:"Device Make",
        type:"input",
    },
    {
        id:"status",
        name:"status", 
        label:"Status",
        type:"select",
    },
    {
        id:"serialNumber",
        name:"serialNumber", 
        label:"Serial Number",
        type:"input",
    },
    {
        id:"dateInstalled",
        name:"dateInstalled", 
        label:"Date Installed",
        type:"datePicker",
    },
    {
        id:"macAddress",
        name:"macAddress", 
        label:"MACAddress",
        type:"input",
    },
    {
        id:"ipAddress",
        name:"ipAddress", 
        label:"IP Address",
        type:"input",
    },
    {
        id:"topicName",
        name:"topicName", 
        label:"Topic Name",
        type:"input",
    },
    
]